import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Figure from 'react-bootstrap/Figure'

import descriptionImg from '.././../../../assets/landing/description-img.jpg'

const Description = () => {
    return (
        <>
            <section style={{backgroundColor: "#A6A6A6", color: "#262626"}}>
                <Container style={{padding: "5rem"}}>
                    <Row>
                        <Col lg={5} md={3} style={{height: "100%"}}>
                            <Figure>
                                <Figure.Image src={descriptionImg}></Figure.Image>
                            </Figure>
                        </Col>
                        <Col lg={1} md={3}/> 
                        <Col lg={6} md={6}>
                            <p>
                                Transform your yard with our full-service concrete work, including stamped and broom finish styles. 
                                We are experts in all types of construction, including fencing, landscaping, pergolas, retaining walls, and masonry.
                            </p>
                            <p>
                                We are located in the Denver front range area, and we are available for hire throughout the day. 
                                Call us at (720) 665-7519 to get started!
                            </p>
                            <h5>Stay Connected Throughout Your Project</h5>
                            <span>
                                We make it a priority to maintain open lines of communication with our clients throughout the entire 
                                project, from start to finish. If you'd like a complimentary quote or have any inquiries or specific 
                                requests, please don't hesitate to reach out. We're eager to be of service to you!
                            </span>

                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{backgroundColor: "#262626", color: "#F2F2F2"}}>
                <Container style={{padding: "5rem"}}>
                    <Row>
                        <Col sm={6}>
                            <h5>On-time Completion</h5>
                            <p>
                                We will work with you to meet deadlines and coordinate with other related projects. We will maintain open 
                                communication with you to keep you up to date on the status of your job. We do this to guarantee that the 
                                project is completed according to your preferences.
                            </p>
                        </Col>
                        <Col sm={6}>
                            <h5>Great Service</h5>
                            <p>
                            We combine our industry knowledge, the highest quality building supplies and equipment, and our dedication to 
                            deliver exceptional service to our clients. We will stay in touch, keeping you up to date on both the paperwork 
                            and renovation process
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Description;