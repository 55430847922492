import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import Img from '../../../../assets/contact/contact-img.jpg';

const ServiceCard = ({ title, description, Img }) => {
  return (
    <Card className="service-card" style={{backgroundColor: "#595959", color:"#D1D1D1", border: "solid 0.5px #262626"}}>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Img src={Img} />  
          <strong>Key Offering:</strong>
          <ul>
            {description.map((subService, index) => (
              <li key={index}>{subService}</li>
            ))}
          </ul>
      </Card.Body>
    </Card>
  );
};

export default ServiceCard;
