import styled from 'styled-components'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import { Link } from "react-router-dom";

export const NavigationContainer = styled(Row)`
background-color: #81767a;
  &.bg-light{
    background-color: #81767a;
    min-height: 106px;
  }
  &.navbar-hidden {
    top: -106px;
  }

`

export const NavigationBar = styled(Navbar)`
  transition: top 0.3s ease;
  color: #e6dadb;
  padding-right: 2rem;
  padding-left: 2rem;
  
  &.navbar-hidden {
    top: -100px;
  }

  &.bg-light{
    background-color: #595959 !important;
  }

  .navbar-collapse{
    padding-left: 1rem;
  }

`

export const LeftNavigationNav = styled(Nav)`
  a{
    font-size: 1.2rem;
    color: #F2F2F2;
  }
`
export const RightNavigationNav = styled(Nav)`
  padding-right: 30px;
  a{
    color: #e6dadb;
  }
`

export const NavLink = styled(Link)`
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  text-decoration: none;
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
`
