import HeroSection from "./components/hero-section/hero-section.component";
import Description from "./components/description/description.component";
import ContactForm from "./components/contact/contact.component";

import './landing.styles.jsx'


const Landing = () => {
  return (
    <>
      <HeroSection/>
      <Description/>
      <ContactForm/>
    </>

  );
};

export default Landing;