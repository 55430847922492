import { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import { postAPI } from '../../../../utils/api';

const defaultFormFields = {
  name: '',
  phone: '',
  email: '',
  message: "",
  token: ''
};

const ContactForm = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const [submitStatus, setSubmitStatus] = useState("none")
    const { name, phone, email, message } = formFields;
    
    const captchaRef = useRef(null)

    const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
    };    

  const clearFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const verify = () =>{
    if (captchaRef && captchaRef.current){
        let token = captchaRef.current.getValue()
        if (token!="" && token != null)
            setFormFields({...formFields, token})
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus("loading")
    try{
        if (captchaRef && captchaRef.current && formFields.token != ""){
            const res = await postAPI("/contact", formFields)
            if (res.statusCode == 200){
                clearFormFields();
                captchaRef.current.reset();
                window.alert("Thank you for contacting us! We have received your message and will get back to you soon.")
                setSubmitStatus("true")
            }
        }
        else{
            window.alert("ReCaptcha is Required")
            setSubmitStatus("")
        }            
    }
    catch(err){
        console.log(err)
        setSubmitStatus("false")
    }
}

    const submitFeedback = () => {
        if (submitStatus == "false"){
            return(
                <Button variant="primary" type="submit" style={{backgroundColor:"#0D0D0D", border: "solid 0.5px #262626"}}>
                    <span style={{color:"red", fontWeight:"900"}}>  &#10008; </span>
                </Button>
            )
        }
        else if(submitStatus == "true"){
            return(
            <Button variant="primary" type="submit" style={{backgroundColor:"#0D0D0D", border: "solid 0.5px #262626"}} disabled>
                <span style={{color:"green", fontWeight:"900"}}>  &#10004; </span>
            </Button>
        )
        }
        else if(submitStatus == "loading"){
            return(
                <Button variant="primary" type="submit" disabled style={{backgroundColor:"#0D0D0D", border: "solid 0.5px #262626"}}>
                    <Spinner animation="border" />
                </Button>
            )
        }
        return(
            <Button type="submit" className='custom-button-primary' size='lg' style={{backgroundColor:"#0D0D0D", border: "solid 0.5px #262626"}}>
                Get In Touch
            </Button>
        )
    }
    return (
        <>
            <section style={{backgroundColor: "#A6A6A6", color: "#262626"}}>
                <Container style={{padding: "5rem"}}>
                    <Row>
                        <Col className='text-center'>
                            <h3>Drop us a Line</h3>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <Form className="row g-2" onSubmit={handleSubmit}>

                                <FloatingLabel controlId="floatingInput" label="Name" className="mb-3">
                                <Form.Control type="text" placeholder="name" name='name' onChange={handleChange} value={name} required/>
                                </FloatingLabel>

                                <FloatingLabel controlId="floatingInput" label="Email" className="mb-3">
                                <Form.Control type="email" placeholder="email" name='email' onChange={handleChange} value={email} required/>
                                </FloatingLabel>
                                <span>format: xxx-xxx-xxxx</span>
                                <FloatingLabel controlId="floatingInput" label="Phone" className="mb-3">
                                <Form.Control type="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" title="Format: xxx-xxx-xxxx" placeholder="xxx-xxx-xxxx" name='phone' onChange={handleChange} value={phone} required/>
                                </FloatingLabel>
                                

                                <FloatingLabel controlId="floatingInput" label="Message" className="mb-3">
                                <Form.Control as="textarea" placeholder="message" name='message' onChange={handleChange} value={message} style={{ height: '150px' }} required/>
                                </FloatingLabel>

                                <ReCAPTCHA sitekey={"6Ld9vU4oAAAAAEAK6ThG9Be--sfZF5TJGf2ZFWVB"} ref={captchaRef} onChange={verify}/>

                                <br/>

                                {submitFeedback()}

                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ContactForm;