import concreteImg from '../../assets/services/concrete-service.jpg';
import fencingImg from '../../assets/services/fencing-service.jpg';
import landscapingImg from '../../assets/services/landscaping-service.jpg';
import pergolasImg from '../../assets/services/pergolas-service.jpg';
import retainingWallsImg from '../../assets/services/retaining-wall2.jpg';
import masonryImg from '../../assets/services/masonry-service.jpg';
import outdoorImg from '../../assets/services/Outdoor-service.jpg';
import demolitionImg from '../../assets/services/demolition-service.jpg';
import excavationImg from '../../assets/services/Excavation-service.jpg';
import drivewayImg from '../../assets/services/driveway-service.jpg';

const servicesList = [
  {
    title: 'Concrete Services',
    description: [
      'Stamped Concrete',
      'Concrete Repair',
      'Decorative Concrete',
      'Concrete Cutting and Removal',
      'Concrete Sealing',
    ],
    image: concreteImg,
  },
  {
    title: 'Fencing Services',
    description: [
      'Privacy Fences',
      'Chain-Link Fences',
      'Aluminum Fences',
      'Wood Fences',
      'Vinyl Fences',
      'Gate Installation and Repair',
    ],
    image: fencingImg,
  },
  {
    title: 'Landscaping Services',
    description: [
      'Landscape Design',
      'Lawn Installation and Maintenance',
      'Irrigation Systems',
      'Tree and Shrub Planting',
      'Hardscaping (e.g., patios, walkways)',
      'Landscape Lighting',
    ],
    image: landscapingImg,
  },
  {
    title: 'Pergolas and Gazebos',
    description: [
      'Custom Pergola Design',
      'Gazebo Installation',
      'Pergola Repair and Maintenance',
      'Arbor Construction',
    ],
    image: pergolasImg,
  },
  {
    title: 'Retaining Walls',
    description: [
      'Segmental Retaining Walls',
      'Gravity Retaining Walls',
      'Timber Retaining Walls',
      'Retaining Wall Repair',
      'Drainage Solutions',
    ],
    image: retainingWallsImg,
  },
  {
    title: 'Outdoor Living Spaces',
    description: [
      'Outdoor Kitchens',
      'Fire Pits and Fireplaces',
      'Deck Construction',
      'Patio Construction',
      'Swimming Pool Design and Installation',
    ],
    image: outdoorImg,
  },
  {
    title: 'Driveways and Walkways',
    description: [
      'Driveway Installation and Repair',
      'Walkway and Pathway Construction',
      'Paver Driveways',
    ],
    image: drivewayImg,
  },
  {
    title: 'Masonry Services',
    description: [
      'Brickwork',
      'Stone Masonry',
      'Chimney Repair and Rebuilding',
      'Masonry Restoration',
    ],
    // image: masonryImg,
  },
  {
    title: 'Demolition Services',
    description: [
      'Interior Demolition',
      'Structural Demolition',
      'Site Clearing and Grading',
    ],
    // image: demolitionImg,
  },
  {
    title: 'Excavation Services',
    description: [
      'Site Excavation',
      'Trenching',
      'Foundation Excavation',
      'Earthmoving',
    ],
    // image: excavationImg,
  },
];

export default servicesList;
