import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Figure from 'react-bootstrap/Figure';

import ContactForm from '../landing/components/contact/contact.component';
import ContactImg from '../../assets/contact/contact-img.jpg'

const Contact = () => {
    return (
    <section style={{paddingTop : "1rem", backgroundColor:"#262626", color:"#F2F2F2"}}>
      <Container>
        <Row>
          <Col sm={6}>
            <h3>Get A Free Quote</h3>
            <p>
              We prioritize maintaining continuous communication with our clients from the project's 
              inception to its completion. If you require a free quote or have any questions or specific 
              needs, please feel free to contact us. We're enthusiastic about assisting you!
            </p>
            <h3>Dav Construction LLC</h3>
            <p>Phone - <span>720.665.7519</span></p>
            <p>Email - <span>davconstruction94@gmail.com</span></p>
          </Col>
          <Col sm={6}>
            <Figure>
              <Figure.Image
                fluid
                alt="171x180"
                src={ContactImg}
              />
            </Figure>
          </Col>
        </Row>
      </Container>
      <ContactForm/>
    </section>
  );
}

export default Contact;