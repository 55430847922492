import { Routes, Route } from 'react-router-dom';

import Navigation from './routes/navigation/navigation.route';
import Landing from './routes/landing/landing.route';
import Services from './routes/services/services.route';
import About from './routes/about/about.route';
import Contact from './routes/contact/contact.route';

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigation bgTop="transparent" bgScroll="light"/>}>
        <Route index element={<Landing/>}/>
      </Route>
      <Route path='/services' element={<Navigation bgTop="light" bgScroll="light"/>}>
        <Route index element={<Services/>}/>
      </Route>
      <Route path='/about' element={<Navigation bgTop="light" bgScroll="light"/>}>
        <Route index element={<About/>}/>
      </Route>
      <Route path='/contact' element={<Navigation bgTop="light" bgScroll="light"/>}>
        <Route index element={<Contact/>}/>
      </Route>
    </Routes>
  );
}

export default App;
