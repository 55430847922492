import Figure from 'react-bootstrap/Figure'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import dadImg from '../../../../assets/about/Juan.jpg'
import davidImg from '../../../../assets/about/dav.jpg'
import youngerImg from '../../../../assets/about/nicolas.jpg'

const IMG = {
    'Juan Fancisco Vazquez Flores' : dadImg,
    'Luis David Vazuez Miranda': davidImg,
    "Nicoloas Alexander Vazuez Miranda": youngerImg
}

const TeamMemberCard = (props) => {
    const { name, description } = props
    return (
        <Row>
            <Col>
              <Card style={{backgroundColor:"#595959", color:"#F2F2F2"}}>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <Figure>
                        <Figure.Image
                          width={1920}
                          height={1080}
                          alt="Family"
                          src={IMG[name]}
                        />
                      </Figure>
                    </Col>
                    <Col md={6}>
                      <Card.Title>{name}</Card.Title>
                      <Card.Text>
                        {description}
                      </Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
        </Row>
  );
}

export default TeamMemberCard;