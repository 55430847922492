import { Outlet, Link } from "react-router-dom";
import { Fragment, useState, useEffect,useContext } from "react";
import Row from 'react-bootstrap/Row';

import Footer from "../footer/footer.route";

import { ReactComponent as  Logo} from "../../assets/logo.svg";

import { NavigationBar, LeftNavigationNav, NavLink, NavigationContainer } from "./navigation.styles.jsx"

const Navigation = (props) => {
  const {bgTop} = props
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const atTop = currentScrollPos <= 10;
      setScrolled(prevScrollpos > currentScrollPos && !atTop);
      setVisible(prevScrollpos > currentScrollPos || atTop)
      setPrevScrollpos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollpos]);

    return (
      <Fragment>
        <NavigationContainer className={ props.bgTop == 'light' ? 'bg-light' : ''}>
          <NavigationBar bg={scrolled || window.innerWidth < 992 ? props.bgScroll : props.bgTop} expand="lg" fixed="top" className={!visible ? 'navbar-hidden' : ''} >
              <NavigationBar.Brand>
                <Link to="/">
                  <Logo width="80" height="80" alt="Logo"/>
                </Link>
                
              </NavigationBar.Brand>
              <h5 style={{color: "black"}} >Dav Construction LLC</h5>
              
              <NavigationBar.Toggle aria-controls="navbar-nav" />
              <NavigationBar.Collapse id="navbar-nav" >
                <LeftNavigationNav className="me-auto">
                  <NavLink to="/">Home</NavLink>
                  <NavLink to="/services">Services</NavLink>
                  <NavLink to="/about">About</NavLink>
                  <NavLink to="/contact">Contact</NavLink>
                </LeftNavigationNav>
              </NavigationBar.Collapse>
              {/* <h3>Dav Construction LLC</h3> */}
          </NavigationBar>

        </NavigationContainer>
        <Row>
          <Outlet/>
        </Row>
        <Footer/>
      </Fragment>
    );
  }

export default Navigation