import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const Footer = () => {
    return (
        <footer style={{backgroundColor: "#262626", color:"#F2F2F2", paddingTop: "2rem"}}>
            <Container>
                <Row>
                    <Col className="text-center">
                        <h3>Dav Construction LLC</h3>
                    </Col>
                </Row>
                <br/>
                <Row className="text-center">
                    <Col>
                        <a href="https://www.facebook.com/davconstrucllc/" target="_blank">
                            <FacebookIcon style={{color:"#e6dadb", fontSize:"2.5rem"}}/>
                        </a>
                        <a style={{cursor:"default"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                        <a href="https://www.instagram.com/davc.onstruction/" target="_blank">
                            <InstagramIcon style={{color:"#e6dadb", fontSize:"2.5rem"}}/>
                        </a>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col className="text-center">
                        <a href="tel:7206657519" style={{color:"#e6dadb", fontSize:"1.25rem"}}>720.665.7519</a>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col xs={6} className="">
                        Copyright © 2023 Dav Constuction LLC - All Rights Reserved.
                    </Col>
                    <Col xs={6} style={{textAlign: "right"}}>
                        Build by Oscar Delgado
                    </Col>
                </Row>
            </Container>        
        </footer>
    )
}

export default Footer;