import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ReactComponent as  Logo} from "../../../../assets/logo.svg";

import { HeroSectionContainer } from './hero-section.styles.jsx'

const HeroSection = () => {
  return (
    <HeroSectionContainer className="landing-section overlay overflow-hidden bg-black" data-aos="fade-in" >
    <Container fluid="md" className="d-flex flex-column py-20 foreground min-vh-100">
        <Row className="row align-items-center justify-content-center justify-content-lg-between my-auto">
        <Col className="row align-items-center justify-content-center justify-content-lg-between my-auto">
            <h1 className="lh-sm text-white mb-4"> <Logo alt="Logo"/></h1>
            <p className="lead text-white mb-4">Interested? Get In touch! Please submit a request to get a free consulation.</p>
        </Col>
        </Row>
    </Container>
    </HeroSectionContainer>

  );
};

export default HeroSection;