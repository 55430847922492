import Figure from 'react-bootstrap/Figure'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'

import { HeroSectionContainer } from './about.styles.jsx'

import TeamMemberCard from './components/team-member-card/team-member-card.component.jsx'

import familyImg from '../../assets/workers.jpg'

const About = () => {
    return (
    <>
      <section style={{backgroundColor: '#D1D1D1', color:"#262626"}}>
        <Figure>
          <Figure.Image
            width={1920}
            height={1080}
            alt="Family"
            src={familyImg}
          />
        </Figure>
      </section>
      <section style={{backgroundColor: '#D1D1D1', color: "#262626"}}>
        <Container>
          <Row>
            <Col>
              <h1>About Us</h1>
              <hr/>
              <p>
              We're thrilled to introduce our brand-new family-owned concrete construction company. 
              It's a venture led by my father and my two brothers, and we're excited to bring our 
              combined decades of experience to the world of construction. Our focus is on concrete work, 
              and while we may be new to the industry, our dedication and passion will drive us to establish 
              ourselves as a reliable and top-quality choice for all your concrete construction needs.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Our Mission</h2>
              <hr/>
              <p>
              Our mission is to provide our clients with the highest quality concrete construction services 
              possible. We believe that our clients should be able to enjoy the best possible experience 
              with our concrete construction company, and that we should be able to continue to grow and improve 
              our services.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Our Vision</h2>
              <hr/>
              <p>
              Our vision is to be a company that will continue to grow and improve in the future. We believe that 
              our clients should be able to enjoy the best possible experience with our concrete construction company, 
              and that we should be able to continue to grow and improve our services.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section style={{backgroundColor: '#D1D1D1'}}>
        <Container>
          <h2 style={{color: "#262626"}}>Our Team</h2>
          <hr/>
          <TeamMemberCard 
            name = "Luis David Vazuez Miranda"
            description = "As the oldest brother, I am taking the lead in our family-owned concrete construction company. I'm excited to apply the knowledge and skills I've acquired over the years to steer our venture towards success. My role involves project management, client relations, and overseeing day-to-day operations. I will be the point person for our clients, ensuring that we meet their expectations and deliver top-notch concrete solutions. With my father's guidance and my brother's support, I am committed to upholding our family's reputation for excellence."
          />
          <br/>
          <TeamMemberCard 
            name = "Juan Fancisco Vazquez Flores"
            description = "Our father brings a wealth of knowledge and experience to our new concrete construction venture. With decades of hands-on experience in the construction industry, he serves as the foundation of our team. His expertise will be invaluable in guiding our business, providing insights, and ensuring the highest standards of quality and professionalism in all our projects. He will play a critical advisory role, offering his wisdom to help us navigate the challenges and opportunities that come our way."
          />
          <br/>
          <TeamMemberCard 
            name = "Nicoloas Alexander Vazuez Miranda"
            description = "As the younger brother, I see this venture as an incredible opportunity to learn from both my father and my older brother. I'll be actively involved in various aspects of the business, from working on concrete projects to assisting with administrative tasks. This hands-on experience will allow me to absorb the invaluable knowledge and skills passed down by my family. My role will evolve as I gain expertise, and I'm eager to contribute to the growth and success of our company. Learning from the best, I'm excited to become an integral part of our family's concrete construction legacy"
          />
          <br/>
        </Container>
      </section>
    </>
  );
}

export default About;